const formDataToObject = (formData: FormData) =>
	Array.from(formData.entries()).reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {})

const states = {
	success: 'success',
	error: 'error',
	default: 'default'
}

interface Refs {
	inputField: HTMLInputElement
}

export default {
	name: 'ajaxForm',
	component() {
		// @ts-expect-error setup Alpine types correctly?
		const form: HTMLFormElement = this.$el
		const endpoint = form.getAttribute('action')!
		const method = form.getAttribute('method') || 'POST'

		return {
			loading: false,
			statusText: '',
			status: states.default,
			$refs: {} as Refs,

			async handleSubmit(e) {
				if (this.loading) return

				this.loading = true

				const formData = new FormData(form)
				const body = JSON.stringify(formDataToObject(formData))

				try {
					const response = await fetch(endpoint, {
						method,
						body
					})
					const data = await response.json()
					this.statusText = data.message
					this.status = states.success
					this.$refs.inputField.value = 'Thank you!'
				} catch (error) {
					this.statusText = 'Something went wrong, please try later'
					this.status = states.error
				} finally {
					this.loading = false
				}
			}
		}
	}
}
